import { LocalizationProvider, LocalizationProviderProps, MuiPickersAdapter } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DateFnsProviderProps<TDate extends Date> = Omit<LocalizationProviderProps<TDate, any>, 'dateAdapter'> & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dateAdapter?: new (...args: any) => MuiPickersAdapter<TDate>;
};

export default function DateFnsProvider({ children, ...props }: DateFnsProviderProps<Date>) {
  const { dateAdapter, ...localizationProps } = props;
  return (
    <LocalizationProvider dateAdapter={dateAdapter || AdapterDateFns} {...localizationProps}>
      {children}
    </LocalizationProvider>
  );
}
