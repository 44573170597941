import { createFileRoute } from '@tanstack/react-router';
import { typographyVariants } from '@/components/ui/typography';
import { env } from '@/env';
import { getAuthorizationHeader } from '@/jotai/account';
import { fetchResponseHandler } from '@/lib/fetch-utils';
import { cn } from '@/lib/utils';
import LogbookActivityCreateView from '@/routeComponents/logbookActivity/LogbookActivityCreateView';
import { LogbookAPI } from '@/types/logbook';

const fetchLogbook = async (id: string) => {
  try {
    const response = await fetch(
      `${env.VITE_API_BASE_URL}/assistant/student/logbooks/${id}?expand=activities,rotation,specialty`,
      {
        headers: await getAuthorizationHeader(),
      }
    ).then(fetchResponseHandler<LogbookAPI>());
    return response;
  } catch (e) {
    console.log(e); // catch error
  }
};

const LogbookActivityCreatePage = () => {
  const logbook = Route.useLoaderData();
  return (
    <main className="container mx-auto py-8">
      <h1 className={cn(typographyVariants({ variant: 'h2' }))}>
        Create New Logbook Activity - Period - {logbook?.rotation?.start_date} to {logbook?.rotation?.end_date}
      </h1>
      <LogbookActivityCreateView logbook={logbook} />
    </main>
  );
};

export const Route = createFileRoute('/_authenticated/logbook-activity/create/$logbookId')({
  loader: async ({ params }) => {
    return fetchLogbook(params?.logbookId);
  },
  component: LogbookActivityCreatePage,
});
