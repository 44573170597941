import { Create } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { Link } from '@tanstack/react-router';

const ButtonAddActivities = ({
  isBeforeRotationPeriod,
  isAfterSubmissionPeriod,
  status,
  logbookId,
}: {
  isBeforeRotationPeriod: boolean;
  isAfterSubmissionPeriod: boolean;
  status: string;
  logbookId: number;
}) => {
  if (isBeforeRotationPeriod || status === 'submitted' || (isAfterSubmissionPeriod && status !== 'return')) {
    return null;
  }
  return (
    <Button startIcon={<Create />}>
      <Link to={`/logbook-activity/create/$logbookId`} params={{ logbookId: String(logbookId) }}>
        <Typography>Add Activities</Typography>
      </Link>
    </Button>
  );
};

export default ButtonAddActivities;
