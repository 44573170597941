import React from 'react';
import { useNavigate } from '@tanstack/react-router';
import { useSetAtom } from 'jotai';
import { flushSync } from 'react-dom';
import { env } from '@/env';
import { SessionToken, sessionTokenAtom } from '@/jotai/account';
import { fetchResponseHandler } from '@/lib/fetch-utils';

const GetFlashView = () => {
  const [error, setError] = React.useState<string | null>(null);
  const navigate = useNavigate({ from: '/one-pass' });
  const setAccount = useSetAtom(sessionTokenAtom);

  React.useEffect(() => {
    const fetchFlash = async () => {
      return fetch(`${env.VITE_API_BASE_URL}/one-pass/fetch-user`, {
        credentials: 'include', // it is important to include credentials as this is a cross-origin request
      })
        .then(fetchResponseHandler<SessionToken>())
        .then((data) => {
          flushSync(() => {
            setAccount(data);
            navigate({ to: '/', search: true, replace: true });
          });
        })
        .catch((e) => {
          setError(e.message);
        });
    };
    fetchFlash();
  }, [navigate, setAccount]);

  if (error) {
    return <div>{error}</div>;
  }

  return <div>Loading...</div>;
};

export default GetFlashView;
