import { Paper, Typography } from '@mui/material';
import { createFileRoute } from '@tanstack/react-router';

function Dashboard() {
  return (
    <main className="container space-y-8 py-8">
      <Paper variant="outlined" className="p-4">
        <Typography>
          The logbook activity records will be locked after submission, but you can still add news records. If you wish
          to make changes for the old records after submission, please request your supervisor(s) to unsign or return
          the logbook.
        </Typography>
      </Paper>
    </main>
  );
}

export const Route = createFileRoute('/_authenticated/')({
  component: () => <Dashboard />,
});
