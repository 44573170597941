import React from 'react';
import { UploadFile } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

type ButtonSubmitDialogProps = {
  isBeforeRotationPeriod: boolean;
  submittable: boolean;
  status: string;
  onSubmit: () => void;
};

function renderSubmitButtonLabel(status: string, submittable: boolean) {
  if (status === 'submitted') {
    return 'Submitted';
  }
  if (status !== 'submitted' && !submittable) {
    return 'Submission deadline passed';
  }
  if (status === 'return' && submittable) {
    return 'Re-Submit';
  }
  return 'Submit';
}

const ButtonSubmitDialog = ({ isBeforeRotationPeriod, submittable, status, onSubmit }: ButtonSubmitDialogProps) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = React.useCallback(() => setOpen(true), []);
  const handleClose = React.useCallback(() => setOpen(false), []);
  const wrappedOnSubmit = React.useCallback(() => {
    handleClose();
    onSubmit();
  }, [onSubmit, handleClose]);

  if (isBeforeRotationPeriod) {
    return null;
  }

  return (
    <div>
      <Button
        onClick={handleOpen}
        startIcon={<UploadFile />}
        variant="contained"
        color={!submittable ? 'error' : 'success'}
        disabled={!submittable}
      >
        {renderSubmitButtonLabel(status, submittable)}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Submit this logbook?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            After you submitted this logbook, you will not able to edit the activities anymore, are you sure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={wrappedOnSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ButtonSubmitDialog;
