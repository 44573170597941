import React from 'react';
import { Card, CardActions, CardContent, CardHeader } from '@mui/material';
import { atom } from 'jotai';
import { Pagination } from '@/components/list/pagination';
import { env } from '@/env';
import { SearchState, useDataGridData } from '@/hooks/useDataGridData';
import { getAuthorizationHeader } from '@/jotai/account';
import { useOpenSnackbar } from '@/jotai/snackbar';
import { fetchResponseHandler } from '@/lib/fetch-utils';
import { dateToDbFormat } from '@/lib/utils';
import { LogbookAPI } from '@/types/logbook';
import { LogbookActivityAPI } from '@/types/logbookActivity';
import { RotationAPI } from '@/types/rotation';
import ButtonAddActivities from './ButtonAddActivities';
import ButtonSubmitDialog from './ButtonSubmitDialog';
import LogbookActivityInlineView from './LogbookActivityInlineView';

const logbookAtom = atom<SearchState>({
  form: {
    expand: 'rotation,rotation.specialty',
  },
  sort: { key: 'rotation.start_date', asc: true },
  page: 0,
  pageSize: 5,
  totalElements: 10,
  totalPages: 1,
  numberOfElements: 0,
});

function LogbookView() {
  const snackbarOpen = useOpenSnackbar();

  const {
    result: { data, refetch },
    searchState,
    pageChange,
  } = useDataGridData<LogbookAPI>({
    baseUrl: `/assistant/student/logbooks`,
    atom: logbookAtom,
    dto: true,
    dtoDateFields: ['rotation.start_date', 'rotation.end_date', 'rotation.submit_date'],
    // dtoDatetimeFields: ['activities.datetime_start', 'activities.datetime_end'], // I try to infer the type to nested array object but failed
  });

  const onLogbookSubmit = React.useCallback(
    async (logbookId: number) => {
      return await fetch(`${env.VITE_API_BASE_URL}/assistant/student/logbooks/${logbookId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          ...(await getAuthorizationHeader()),
        },
        body: JSON.stringify({ status: 'submitted' }),
      })
        .then(fetchResponseHandler<LogbookActivityAPI>())
        .then(() => {})
        .then(() => snackbarOpen('Logbook submitted successfully'))
        .then(() => refetch())
        .catch(() => snackbarOpen('Failed to submit logbook'));
    },
    [snackbarOpen, refetch]
  );

  return (
    <div className="flex flex-col gap-4">
      {data?.items.map((logbook) => {
        const key = `logbook-${logbook.id}`;
        // TODO: DTO not able to parse the nested array object, will fix it later
        const isBeforeRotationPeriod =
          (logbook?.rotation && logbook?.rotation?.start_date > dateToDbFormat(new Date())) || false;
        const isAfterRotationPeriod =
          (logbook?.rotation && logbook?.rotation?.end_date < dateToDbFormat(new Date())) || false;
        const isAfterSubmissionPeriod = logbook?.rotation?.submit_date
          ? logbook?.rotation?.submit_date < dateToDbFormat(new Date())
          : isAfterRotationPeriod;
        const editableAndDeletable =
          logbook.status !== 'submitted' && (!isAfterSubmissionPeriod || logbook.status == 'return');
        const submittable =
          logbook.status !== 'submitted' &&
          !isBeforeRotationPeriod &&
          (!isAfterSubmissionPeriod || logbook.status == 'return');
        return (
          <Card key={key}>
            <CardHeader
              title={`Period - ${logbook?.rotation?.start_date} to ${logbook?.rotation?.end_date} ${logbook?.rotation?.submit_date ? `(Submission deadline: ${logbook?.rotation?.submit_date})` : `(Submission deadline: ${logbook?.rotation?.end_date})`}`}
              subheader={`Specialty / Discipline - ${logbook?.rotation?.specialty?.name}`}
            />
            <CardContent>
              <LogbookActivityInlineView
                insertable={false}
                exportable={false}
                logbookId={logbook.id}
                editable={editableAndDeletable}
                deletable={editableAndDeletable}
                rotation={logbook.rotation as RotationAPI}
              />
            </CardContent>
            <CardActions>
              <ButtonAddActivities
                isBeforeRotationPeriod={isBeforeRotationPeriod}
                isAfterSubmissionPeriod={isAfterSubmissionPeriod}
                status={logbook.status}
                logbookId={logbook.id}
              />
              <ButtonSubmitDialog
                isBeforeRotationPeriod={isBeforeRotationPeriod}
                submittable={submittable}
                status={logbook.status}
                onSubmit={() => onLogbookSubmit(logbook.id)}
              />
            </CardActions>
          </Card>
        );
      })}
      <div className="text-right">
        <Pagination
          count={searchState.totalElements}
          page={searchState.page}
          rowsPerPage={searchState.pageSize}
          onPageChange={pageChange}
        />
      </div>
    </div>
  );
}

export { LogbookView };
