import z from 'zod';

const envSchema = z.object({
  MODE: z.enum(['development', 'production']),
  BASE_URL: z.string().trim().describe("Frontend's base URL").optional(),
  VITE_API_BASE_URL: z.string().trim().describe("Backend's base URL"),
  VITE_API_LOGIN_URL: z.string().trim().describe("Login URL"),
  VITE_API_LOGOUT_URL: z.string().trim().describe("Logout URL"),
});

export const env = envSchema.parse(import.meta.env);
