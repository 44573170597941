import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import {
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { nanoid } from 'nanoid';
import BatchActionPanel from './BatchActionPanel';

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
  rowCreatingId: string | undefined;
  rowEditingId: string | undefined;
  insertable?: boolean;
  exportable?: boolean;
  filterable?: boolean;
}

function EditToolbar(props: EditToolbarProps) {
  const {
    setRows,
    setRowModesModel,
    rowCreatingId,
    rowEditingId,
    insertable = true,
    exportable = true,
    filterable = true,
  } = props;

  const handleClick = () => {
    const id = nanoid();
    setRows((oldRows) => [...oldRows, { id: id, isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit },
    }));
  };

  return (
    <GridToolbarContainer>
      {insertable && !rowCreatingId && !rowEditingId && (
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Add record
        </Button>
      )}
      <GridToolbarColumnsButton />
      {filterable && <GridToolbarFilterButton />}
      {exportable && <GridToolbarExport />}
      <div className="flex-1"></div>
      <BatchActionPanel />
    </GridToolbarContainer>
  );
}

export default EditToolbar;
