import { createFileRoute } from '@tanstack/react-router';
import GetFlashView from '@/routeComponents/login/GetFlashView';

const GetFlashViewContainer = () => {
  return (
    <main className="container mx-auto max-w-lg py-8">
      <GetFlashView />
    </main>
  );
};

export const Route = createFileRoute('/_unauthenticated/one-pass')({
  component: GetFlashViewContainer,
});
