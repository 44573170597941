import { FlattenKeys } from "@/types/helper";
import { dateToDbFormat, datetimeToDbFormat } from "./utils";

export type ParseFields<T, DK, BK> = {
    [P in keyof T]: P extends DK ? Date : P extends BK ? boolean : T[P];
};


export class DTOFactory<T> {
    getDTO = function <DK extends FlattenKeys<T>, BK extends FlattenKeys<T>>(dateFields: DK[] = [], booleanFields: BK[] = [], dateTimeFields: DK[] = []) {
        type Parsed = ParseFields<T, DK, BK>;
        type Formatted = Omit<Parsed, DK | BK> & { [P in keyof T]: P extends DK ? string : P extends BK ? number : T[P]; };
        return {
            parse: function (obj: T): Parsed {
                const newObj = { ...obj } as Parsed;
                for (const key of booleanFields) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    ((newObj as any)[key] as unknown) = Boolean((newObj as any)[key] as unknown);
                }
                for (const key of dateFields) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    ((newObj as any)[key] as unknown) = new Date((newObj as any)[key] as string | number | Date);
                }
                for (const key of dateTimeFields) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    ((newObj as any)[key] as unknown) = new Date((newObj as any)[key] as string | number | Date);
                }
                return newObj;
            },
            format: function (obj: Parsed): Formatted {
                const newObj = { ...obj } as Formatted;
                for (const key of booleanFields) {

                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    if (!(newObj as any)[key]) continue;

                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    ((newObj as any)[key] as unknown) = ((newObj as any)[key] as boolean) ? 1 : 0;
                }
                for (const key of dateFields) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    if (!(newObj as any)[key]) continue;

                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    ((newObj as any)[key] as unknown) = dateToDbFormat(((newObj as any)[key] as Date));
                }
                for (const key of dateTimeFields) {

                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    if (!(newObj as any)[key]) continue;
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    ((newObj as any)[key] as unknown) = datetimeToDbFormat(((newObj as any)[key] as Date));
                }
                return newObj;
            },
        };
    };
}
// type DataType = { name: string; birthday: number; happy: number };
// const { parse, format } = new DTOFactory<DataType>().getDTO(['birthday'], ['happy']);
// const r = parse({ name: 'John Doe', birthday: 1234567890, happy: 0 });

// export type Prettify<T> = {
//     [K in keyof T]: T[K];
// } & {};

// type R = Prettify<typeof r>;
// const s = format(r);

// type S = Prettify<typeof s>;
