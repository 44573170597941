import { Button, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import range from 'lodash-es/range';
import { ChevronLeftIcon, ChevronRightIcon, ChevronsLeftIcon, ChevronsRightIcon } from 'lucide-react';
import { cn } from '@/lib/utils';

export interface PageSelectProps
  extends Omit<React.ComponentPropsWithoutRef<typeof Select>, 'value' | 'onValueChange'> {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (page: number) => void;
}
export function PageSelect({ count, page, rowsPerPage, onPageChange, ...props }: PageSelectProps) {
  const lastPage = Math.max(0, Math.ceil(count / rowsPerPage));
  const lowestPage = Math.max(page - 50, 0);
  const highestPage = Math.min(page + 50, lastPage);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange: any = (event: SelectChangeEvent): void => {
    onPageChange(Number(event.target.value) - 1);
  };
  return (
    <Select {...props} value={`${page + 1}`} onChange={handleChange}>
      {range(lowestPage, highestPage).map((option) => (
        <MenuItem key={option} value={`${option + 1}`}>
          {option + 1}
        </MenuItem>
      ))}
    </Select>
  );
}

export interface PaginationProps extends React.TdHTMLAttributes<HTMLTableCellElement> {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (page: number) => void;
}
export function Pagination({ count, page, rowsPerPage, onPageChange }: PaginationProps) {
  const lastPage = Math.max(0, Math.ceil(count / rowsPerPage));

  return (
    <nav className="inline-flex items-center -space-x-1" aria-label="Pagination">
      <Button onClick={() => onPageChange(0)} disabled={page + 1 === 1} variant="text">
        <ChevronsLeftIcon className="h-5 w-5" aria-hidden="true" />
        <span className="sr-only">First</span>
      </Button>
      <Button
        onClick={() => {
          onPageChange(Math.max(0, page - 1));
        }}
        disabled={page + 1 === 1}
        variant="text"
      >
        <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        <span className="sr-only">Previous</span>
      </Button>
      <div className="min-w-[4rem] py-1">
        <PageSelect
          disabled={lastPage <= 1}
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={onPageChange}
        />
      </div>
      <Button onClick={() => onPageChange(Math.min(lastPage, page + 1))} disabled={page + 1 >= lastPage} variant="text">
        <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        <span className="sr-only">Next</span>
      </Button>
      <Button onClick={() => onPageChange(lastPage)} disabled={page + 1 >= lastPage} variant="text">
        <ChevronsRightIcon className="h-5 w-5" aria-hidden="true" />
        <span className="sr-only">Last</span>
      </Button>
    </nav>
  );
}

export interface PaginationTextProps extends React.HTMLAttributes<HTMLSpanElement> {
  count?: number;
  page?: number;
  pageSize: number;
}

export function PaginationText({ count = 0, page = 0, pageSize = 0, className, ...rest }: PaginationTextProps) {
  const from = page * pageSize + 1;
  const to = Math.min((page + 1) * pageSize, count);
  return (
    <span className={cn('inline-block text-sm font-medium', className)} {...rest}>
      Showing {from}-{to} of {count} record(s)
    </span>
  );
}
