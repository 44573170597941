import { createFileRoute, redirect } from '@tanstack/react-router';
import { LoggedInLayout } from '@/components/layout/LoggedInLayout';

export const Route = createFileRoute('/_authenticated')({
  component: LoggedInLayout,
  beforeLoad: ({ context, location }) => {
    if (!context.account?.authKey) {
      throw redirect({
        to: '/login',
        search: { redirect: location.href },
        replace: true,
      });
    }
  },
});
