import { GridColDef, ValueOptions } from '@mui/x-data-grid';
import { atom } from 'jotai';
import { FullFeaturedCrudGrid } from '@/components/list/FullFeaturedCrudGrid';
import { SearchState, useDataGridData } from '@/hooks/useDataGridData';
import { useValuesData } from '@/hooks/useValuesData';
import { required } from '@/lib/utils';
import { LogbookActivityAPI } from '@/types/logbookActivity';

const logbookActivityAtom = atom<SearchState>({
  form: {},
  sort: { key: 'id', asc: true },
  page: 1,
  pageSize: 10,
  totalElements: 10,
  totalPages: 1,
  numberOfElements: 0,
});

type LogbookActivityViewProps = {
  insertable?: boolean;
  filterable?: boolean;
  exportable?: boolean;
};

const LogbookActivityView = ({ insertable = true, filterable = true, exportable = true }: LogbookActivityViewProps) => {
  const {
    result: { refetch, data },
  } = useDataGridData<LogbookActivityAPI>({
    baseUrl: `/assistant/student/logbook-activities`,
    atom: logbookActivityAtom,
    dto: true,
    dtoDatetimeFields: ['date_start', 'date_end'],
  });
  const {
    result: { data: valueLogbookData },
  } = useValuesData({
    baseUrl: '/assistant/values/logbooks ',
  });
  const {
    result: { data: valueSpecialtyData },
  } = useValuesData({
    baseUrl: '/assistant/values/specialties ',
  });
  const {
    result: { data: valueHospitalData },
  } = useValuesData({
    baseUrl: '/assistant/values/hospitals ',
  });
  const {
    result: { data: valueProcedureData },
  } = useValuesData({
    baseUrl: '/assistant/values/procedures ',
  });
  const {
    result: { data: valueActivityCompletionStatusData },
  } = useValuesData({
    baseUrl: '/assistant/values/activity-completion-status',
  });
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 50 },
    {
      field: 'logbook_id',
      headerName: 'Logbook',
      width: 250,
      type: 'singleSelect',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getOptionValue: (value: any) => value?.value,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getOptionLabel: (value: any) => value?.label,
      valueOptions: valueLogbookData.items as ValueOptions[],
      editable: true,
      preProcessEditCellProps: (params) => required(params),
    },
    {
      field: 'datetime_start',
      headerName: 'Start Date',
      width: 200,
      type: 'dateTime',
      editable: true,
    },
    {
      field: 'datetime_end',
      headerName: 'End Date',
      width: 200,
      type: 'dateTime',
      editable: true,
    },
    {
      field: 'specialty_id',
      headerName: 'Specialty',
      width: 150,
      type: 'singleSelect',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getOptionValue: (value: any) => value?.value,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getOptionLabel: (value: any) => value?.label,
      valueOptions: valueSpecialtyData.items as ValueOptions[],
      editable: true,
    },
    {
      field: 'hospital_code',
      headerName: 'Hospital',
      width: 150,
      type: 'singleSelect',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getOptionValue: (value: any) => value?.code,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getOptionLabel: (value: any) => value?.name,
      valueOptions: valueHospitalData.items as ValueOptions[],
      editable: true,
    },
    {
      field: 'ward',
      headerName: 'Ward',
      width: 150,
      editable: true,
    },
    {
      field: 'procedure_id',
      headerName: 'Procedure',
      width: 150,
      type: 'singleSelect',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getOptionValue: (value: any) => value?.id,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getOptionLabel: (value: any) => value?.name,
      valueOptions: valueProcedureData.items as ValueOptions[],
      editable: true,
    },
    {
      field: 'other_procedure',
      headerName: 'Optional Procedure',
      width: 150,
      editable: true,
    },
    {
      field: 'tutor_name',
      headerName: 'Tutor/Trainer Name',
      width: 150,
      editable: true,
    },
    {
      field: 'tutor_position',
      headerName: 'Tutor/Trainer Position',
      width: 150,
      editable: true,
    },
    {
      field: 'completion_status_code',
      headerName: 'Completion Status',
      width: 200,
      type: 'singleSelect',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getOptionValue: (value: any) => value?.code,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getOptionLabel: (value: any) => value?.name,
      valueOptions: valueActivityCompletionStatusData.items as ValueOptions[],
      editable: true,
    },
    {
      field: 'remark',
      headerName: 'Remark',
      width: 150,
      editable: true,
    },
  ];

  return (
    <FullFeaturedCrudGrid
      columns={columns}
      initialRows={data?.items}
      refetch={refetch}
      dataAtom={logbookActivityAtom}
      baseUrl={`/assistant/student/logbook-activities`}
      dto
      dtoDatetimeFields={['date_start', 'date_end']}
      insertable={insertable}
      exportable={exportable}
      filterable={filterable}
    />
  );
};

export default LogbookActivityView;
