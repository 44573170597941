import { Button } from '@mui/material';
import { useNavigate, useParams } from '@tanstack/react-router';
import { SelectElement, TextFieldElement } from 'react-hook-form-mui';
import { DatePickerElement } from 'react-hook-form-mui/date-pickers';
import { z } from 'zod';
import DateFnsProvider from '@/components/form/DateFnsProvider';
import YiiZodForm from '@/components/form/YiiZodForm';
import { env } from '@/env';
import { useValuesData } from '@/hooks/useValuesData';
import { getAuthorizationHeader } from '@/jotai/account';
import { useOpenSnackbar } from '@/jotai/snackbar';
import { fetchResponseHandler } from '@/lib/fetch-utils';
import { dateToDbFormat } from '@/lib/utils';
import { ActivityCompletionStatusAPI } from '@/types/activityCompletionStatus';
import { LogbookAPI } from '@/types/logbook';
import { LogbookActivityAPI } from '@/types/logbookActivity';

const logbookActivityCreateSchema = z
  .object({
    logbook_id: z.string().min(1),
    date_start: z.coerce.date(),
    date_end: z.coerce.date(),
    hospital_code: z.string().min(1),
    procedure_id: z.coerce.number().min(1),
    other_procedure: z.string().nullish(),
    ward: z.string().min(1),
    tutor_name: z.string().min(1),
    tutor_position: z.string().min(1),
    completion_status_code: z.string().min(1),
    remark: z.string().nullish(),
  })
  .refine((schema) => schema.date_end >= schema.date_start, {
    message: 'Start date should be greater than or equal to end date',
    path: ['date_start'],
  });
type LogbookActivityCreateSchema = z.infer<typeof logbookActivityCreateSchema>;

const LogbookActivityCreateView = ({ logbook }: { logbook: LogbookAPI | undefined }) => {
  const params: { logbookId: string } = useParams({ strict: false });
  const snackbarOpen = useOpenSnackbar();
  const navigate = useNavigate();
  const {
    result: { data: valueHospitalData },
  } = useValuesData({
    baseUrl: `/assistant/values/hospitals`,
  });
  const {
    result: { data: valueProcedureData },
  } = useValuesData({
    baseUrl: `/assistant/values/procedures`,
  });
  const {
    result: { data: valueActivityCompletionStatusData },
  } = useValuesData<ActivityCompletionStatusAPI>({
    baseUrl: '/assistant/values/activity-completion-status',
  });
  return (
    <DateFnsProvider>
      <YiiZodForm
        zodSchema={logbookActivityCreateSchema}
        defaultValues={
          {
            logbook_id: params?.logbookId ?? '',
            date_start: logbook?.rotation?.start_date ? new Date(logbook.rotation.start_date) : new Date(),
            date_end: logbook?.rotation?.start_date ? new Date(logbook.rotation.start_date) : new Date(),
            hospital_code: '',
            procedure_id: 0,
            other_procedure: '',
            ward: '',
            tutor_name: '',
            tutor_position: '',
            completion_status_code: '',
            remark: '',
          } satisfies LogbookActivityCreateSchema
        }
        onSubmit={async (values) => {
          const formData = {
            ...values,
            date_start: dateToDbFormat(values.date_start),
            date_end: dateToDbFormat(values.date_end),
          };
          return fetch(`${env.VITE_API_BASE_URL}/assistant/student/logbook-activities`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              ...(await getAuthorizationHeader()),
            },
            body: JSON.stringify(formData),
          })
            .then(fetchResponseHandler<LogbookActivityAPI>())
            .then((data) => {
              if (data) {
                snackbarOpen('Logbook activity created successfully.');
                navigate({ to: '/logbook', replace: true });
              }
            });
        }}
      >
        <div className="flex flex-col gap-6">
          <DatePickerElement
            format="dd/MM/yyyy"
            name="date_start"
            label="Start Date*"
            minDate={logbook?.rotation?.start_date ? new Date(logbook.rotation.start_date) : new Date()}
            maxDate={logbook?.rotation?.end_date ? new Date(logbook.rotation.end_date) : new Date()}
          />
          <DatePickerElement
            format="dd/MM/yyyy"
            name="date_end"
            label="End Date*"
            minDate={logbook?.rotation?.start_date ? new Date(logbook.rotation.start_date) : new Date()}
            maxDate={logbook?.rotation?.end_date ? new Date(logbook.rotation.end_date) : new Date()}
          />
          <SelectElement
            name="hospital_code"
            label="Hospital*"
            options={valueHospitalData?.items ?? []}
            valueKey="code"
            labelKey="name"
            InputLabelProps={{ shrink: true }}
          />
          <SelectElement
            name="procedure_id"
            label="Procedure*"
            options={valueProcedureData?.items ?? []}
            valueKey="id"
            labelKey="name"
            InputLabelProps={{ shrink: true }}
          />
          <TextFieldElement
            name="other_procedure"
            label="Optional Procedure"
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <TextFieldElement name="ward" label="Ward*" fullWidth InputLabelProps={{ shrink: true }} />
          <TextFieldElement
            name="tutor_name"
            label="Tutor/Trainer Name*"
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <TextFieldElement
            name="tutor_position"
            label="Tutor/Trainer Position*"
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <SelectElement
            name="completion_status_code"
            label="Completion Status*"
            options={valueActivityCompletionStatusData?.items ?? []}
            valueKey="code"
            labelKey="name"
            InputLabelProps={{ shrink: true }}
          />
          <TextFieldElement name="remark" label="Remark" fullWidth InputLabelProps={{ shrink: true }} />
          <Button variant="contained" type="submit">
            Create
          </Button>
        </div>
      </YiiZodForm>
    </DateFnsProvider>
  );
};

export default LogbookActivityCreateView;
