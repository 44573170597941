import pickBy from 'lodash-es/pickBy';
import querystring from 'qs';
import { DTOFactory } from './dto';
import { YiiMeta } from '@/hooks/useDataGridData';
import { FlattenKeys } from '@/types/helper';

export async function parseJson<T>(response: Response): Promise<T> {
  return (await response.json()) as T;
}

export class FetchError extends Error {
  status: number;
  statusText: string;
  data: unknown | null;
  constructor({ status, statusText, data }: { status: number; statusText: string; data: unknown | null }) {
    super(statusText);
    this.status = status;
    this.statusText = statusText;
    this.data = data;
  }
}

export function dtoHandler<TReturn extends { items: TRowData[]; meta: YiiMeta }, TRowData, DK extends FlattenKeys<TRowData>, BK extends FlattenKeys<TRowData>>(data: TReturn, dtoDateFields: DK[], dtoBooleanFields: BK[], dtoDatetimeFields: DK[] = []) {
  if (data) {
    const dto = new DTOFactory<TRowData>().getDTO(dtoDateFields, dtoBooleanFields, dtoDatetimeFields);
    return {
      ...(data as NonNullable<TReturn>),
      items: (data as NonNullable<TReturn>)?.items.map(dto.parse) || (data as NonNullable<TReturn>)?.items,
    }
  }
  return data;
}

export function dtoHandlerWithoutMeta<TReturn extends { items: TRowData[] }, TRowData, DK extends FlattenKeys<TRowData>, BK extends FlattenKeys<TRowData>>(data: TReturn, dtoDateFields: DK[], dtoBooleanFields: BK[], dtoDatetimeFields: DK[] = []) {
  if (data) {
    const dto = new DTOFactory<TRowData>().getDTO(dtoDateFields, dtoBooleanFields, dtoDatetimeFields);
    return {
      ...(data as NonNullable<TReturn>),
      items: (data as NonNullable<TReturn>)?.items.map(dto.parse) || (data as NonNullable<TReturn>)?.items,
    }
  }
  return data;
}

export function fetchResponseHandler<TReturn>() {
  return async (response: Response): Promise<TReturn> => {
    if (response.ok) {
      return await parseJson(response);
    }
    let data = null;
    try {
      data = await parseJson(response);
    } catch (e) {
      // no response body
    }
    return Promise.reject(
      new FetchError({
        status: response.status,
        statusText: response.statusText,
        data,
      })
    );
  };
}

export function buildQuery<T>(
  search: {
    form: T; // TODO: typed with search form?
    page: number;
    pageSize: number;
    sort?: { asc: boolean; key: string };
  },
  sendEmpty?: false
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let data: any = {
    ...search.form,
    page: search.page ? search.page + 1 : null, // we stored page zero-based.
    ['per-page']: search.pageSize ? search.pageSize : null,
  };
  if (search.sort?.key) {
    data.sort = `${search.sort.asc ? '' : '-'}${search.sort.key}`;
  }

  // remove empty array
  data = pickBy(
    data,
    // filter empty array as querystring have strange behavior
    (value) => (Array.isArray(value) ? Boolean(value.length) : true)
  );
  if (!sendEmpty) {
    // remove empty input to shorten the params
    data = pickBy(
      data,
      // filter empty array as querystring have strange behavior
      (value) => Boolean(value) || value === 0 || value == false
    );
  }
  return querystring.stringify(data, { arrayFormat: 'repeat' });
}
