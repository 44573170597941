import React from 'react';
import { Typography } from '@mui/material';
import { Link } from '@tanstack/react-router';
import { useSetAtom } from 'jotai';
import { env } from '@/env';
import { redirectToAtom } from '@/jotai/account';

function LoginForm({ redirectTo }: { redirectTo: string }) {
  const setRedirectTo = useSetAtom(redirectToAtom);

  React.useEffect(() => {
    if (redirectTo) {
      setRedirectTo(redirectTo);
    }
  }, [redirectTo, setRedirectTo]);

  return (
    <div>
      <Link
        to={env.VITE_API_LOGIN_URL}
        className="rounded-md outline-none ring-offset-background hover:underline focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2"
      >
        <img src="/onepass-logo-ENG.jpg" alt="Onepass login" />
      </Link>
      <div className="py-8">
        <Typography>
          General Enquiry: <a href="mailto:mcu@med.cuhk.edu.hk">mcu@med.cuhk.edu.hk</a>
        </Typography>
        <Typography>
          Operation Enquiry: <a href="mailto:mcu-admin@med.cuhk.edu.hk">mcu-admin@med.cuhk.edu.hk</a>
        </Typography>
      </div>
    </div>
  );
}

export { LoginForm };
