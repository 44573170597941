import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Collapse, TextField } from '@mui/material';
import { GridColDef, ValueOptions } from '@mui/x-data-grid';
import { atomWithReset } from 'jotai/utils';
import { FullFeaturedCrudGrid } from '@/components/list/FullFeaturedCrudGrid';
import { SearchState, useDataGridData } from '@/hooks/useDataGridData';
import { useValuesData } from '@/hooks/useValuesData';
import { formatDateToISO } from '@/lib/utils';
import { LogbookActivityAPI } from '@/types/logbookActivity';
import { RotationAPI } from '@/types/rotation';

type LogbookActivityInlineViewProps = {
  insertable?: boolean;
  editable?: boolean;
  deletable?: boolean;
  filterable?: boolean;
  exportable?: boolean;
  logbookId?: number | null;
  rotation: RotationAPI;
};

const LogbookActivityInlineView = ({
  insertable = true,
  filterable = true,
  exportable = true,
  editable = true,
  deletable = true,
  logbookId = null,
  rotation,
}: LogbookActivityInlineViewProps) => {
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const rotationStartDate = formatDateToISO(new Date(rotation.start_date));
  const rotationEndDate = formatDateToISO(new Date(rotation.end_date));
  const logbookActivityAtom = React.useMemo(
    () =>
      atomWithReset<SearchState>({
        form: {
          logbook_id: logbookId,
        },
        sort: { key: 'id', asc: true },
        page: 1,
        pageSize: 100,
        totalElements: 10,
        totalPages: 1,
        numberOfElements: 0,
      }),
    [logbookId]
  );
  const {
    result: { refetch, data },
  } = useDataGridData<LogbookActivityAPI>({
    baseUrl: `/assistant/student/logbook-activities`,
    atom: logbookActivityAtom,
    dto: true,
    dtoDateFields: ['date_start', 'date_end'],
  });
  const {
    result: { data: valueHospitalData },
  } = useValuesData({
    baseUrl: '/assistant/values/hospitals ',
  });
  const {
    result: { data: valueProcedureData },
  } = useValuesData({
    baseUrl: '/assistant/values/procedures ',
  });
  const {
    result: { data: valueActivityCompletionStatusData },
  } = useValuesData({
    baseUrl: '/assistant/values/activity-completion-status',
  });

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 50 },
    {
      field: 'date_start',
      headerName: 'Start Date',
      width: 150,
      type: 'date',
      editable: true,
      renderEditCell: (params) => {
        const valueDate = new Date(params.value as Date);
        valueDate.setHours(valueDate.getHours() + 8);
        return (
          <TextField
            type="datetime-local"
            defaultValue={valueDate.toISOString().slice(0, 16)}
            onChange={(event) => {
              params.api.setEditCellValue(
                { id: params.id, field: params.field, value: new Date(event.target.value) },
                event
              );
            }}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: rotationStartDate,
              max: rotationEndDate,
            }}
          />
        );
      },
    },
    {
      field: 'date_end',
      headerName: 'End Date',
      width: 150,
      type: 'date',
      editable: true,
      renderEditCell: (params) => {
        const valueDate = new Date(params.value as Date);
        valueDate.setHours(valueDate.getHours() + 8);
        return (
          <TextField
            type="datetime-local"
            defaultValue={valueDate.toISOString().slice(0, 16)}
            onChange={(event) => {
              params.api.setEditCellValue(
                { id: params.id, field: params.field, value: new Date(event.target.value) },
                event
              );
            }}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: rotationStartDate,
              max: rotationEndDate,
            }}
          />
        );
      },
    },
    {
      field: 'hospital_code',
      headerName: 'Hospital',
      width: 150,
      type: 'singleSelect',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getOptionValue: (value: any) => value?.code,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getOptionLabel: (value: any) => value?.name,
      valueOptions: valueHospitalData.items as ValueOptions[],
      editable: true,
    },
    {
      field: 'ward',
      headerName: 'Ward',
      width: 50,
      editable: true,
    },
    {
      field: 'procedure_id',
      headerName: 'Procedure',
      width: 200,
      type: 'singleSelect',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getOptionValue: (value: any) => value?.id,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getOptionLabel: (value: any) => value?.name,
      valueOptions: valueProcedureData.items as ValueOptions[],
      editable: true,
    },
    {
      field: 'other_procedure',
      headerName: 'Optional Procedure',
      width: 150,
      editable: true,
    },
    {
      field: 'tutor_name',
      headerName: 'Tutor/Trainer Name',
      width: 150,
      editable: true,
    },
    {
      field: 'tutor_position',
      headerName: 'Tutor/Trainer Position',
      width: 180,
      editable: true,
    },
    {
      field: 'completion_status_code',
      headerName: 'Completion Status',
      width: 200,
      type: 'singleSelect',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getOptionValue: (value: any) => value?.code,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getOptionLabel: (value: any) => value?.name,
      valueOptions: valueActivityCompletionStatusData.items as ValueOptions[],
      editable: true,
    },
    {
      field: 'remark',
      headerName: 'Remark',
      width: 150,
      editable: true,
    },
  ];

  return (
    <React.Fragment>
      <Button
        onClick={handleExpandClick}
        aria-expanded={expanded}
        aria-label="show activities"
        endIcon={<ExpandMoreIcon />}
      >
        {data?.items.length} activities added
      </Button>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <FullFeaturedCrudGrid
          columns={columns}
          initialRows={data?.items}
          refetch={refetch}
          dataAtom={logbookActivityAtom}
          baseUrl={`/assistant/student/logbook-activities`}
          dto
          dtoDateFields={['date_start', 'date_end']}
          insertable={insertable}
          cellEditable={editable}
          deletable={deletable}
          exportable={exportable}
          filterable={filterable}
          checkboxSelection={false}
        />
      </Collapse>
    </React.Fragment>
  );
};

export default LogbookActivityInlineView;
