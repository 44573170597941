import { useCallback } from 'react';
import { keepPreviousData, QueryOptions, useQuery } from '@tanstack/react-query';
import { PrimitiveAtom, useAtom } from 'jotai';
import { env } from '@/env';
import { getAuthorizationHeader } from '@/jotai/account';
import { buildQuery, dtoHandler, fetchResponseHandler } from '@/lib/fetch-utils';
import { FlattenKeys } from '@/types/helper';

export interface YiiMeta {
  currentPage: number;
  pageCount: number;
  perPage: number;
  totalCount: number;
}

export interface SearchState {
  form: Record<string, unknown>;
  sort: { key: string; asc: boolean };
  page: number;
  pageSize: number;
  totalElements: number;
  totalPages: number;
  numberOfElements: number;
}

function defaultGetMeta<TRowData>(data: { meta: YiiMeta; items: TRowData[] }) {
  return {
    page: data.meta.currentPage - 1, // zero-based
    pageSize: data.meta.perPage,
    totalElements: data.meta.totalCount,
    totalPages: data.meta.pageCount,
    numberOfElements: data.items.length,
  };
}

function defaultGetItems<TRowData>(data: { meta: YiiMeta; items: TRowData[] }) {
  return data.items ?? [];
}

export function useDataGridData<
  TRowData,
  DK extends FlattenKeys<TRowData> = FlattenKeys<TRowData>,
  BK extends FlattenKeys<TRowData> = FlattenKeys<TRowData>,
>(
  {
    atom,
    baseUrl,
    forceForm = {},
    getItems = defaultGetItems,
    getMeta = defaultGetMeta,
    dto = false,
    dtoDateFields = [],
    dtoBooleanFields = [],
    dtoDatetimeFields = [],
  }: {
    atom: PrimitiveAtom<SearchState>;
    baseUrl: string;
    forceForm?: Record<string, unknown>;
    getItems?: (data: { meta: YiiMeta; items: TRowData[] }) => TRowData[];
    getMeta?: (data: { meta: YiiMeta; items: TRowData[] }) => Omit<SearchState, 'form' | 'sort'>;
    dto?: boolean;
    dtoDateFields?: DK[];
    dtoBooleanFields?: BK[];
    dtoDatetimeFields?: DK[];
  },
  queryOptions?: QueryOptions<{ items: TRowData[] }>
) {
  const [searchState, setState] = useAtom(atom);
  const { form, sort, page, pageSize } = searchState;
  // console.log('searchState', form);
  const querystring = buildQuery({
    form: {
      ...form,
      ...forceForm,
    },
    sort,
    page,
    pageSize,
  });

  const queryKey = `${baseUrl}?${querystring}`;
  const result = useQuery({
    queryKey: [baseUrl, querystring],
    queryFn: async () => {
      const data = await fetch(`${env.VITE_API_BASE_URL}${baseUrl}?${querystring}`, {
        headers: await getAuthorizationHeader(),
      })
        .then(fetchResponseHandler<{ meta: YiiMeta; items: TRowData[] }>())
        .then((data) => {
          if (dto) {
            // console.log('dto', { dtoDateFields, dtoBooleanFields });
            return dtoHandler<{ meta: YiiMeta; items: TRowData[] }, TRowData, DK, BK>(
              data,
              dtoDateFields,
              dtoBooleanFields,
              dtoDatetimeFields
            );
          }
          return data;
        });

      const items = getItems(data);
      const meta = getMeta(data);
      // con sole.log(meta);
      setState((state) => ({
        ...state,
        ...meta,
      }));
      return { items };
    },
    initialData: { items: [] },
    placeholderData: keepPreviousData,
    ...queryOptions,
  });
  // console.log({ isPreviousData, isPlaceholderData, isInitialLoading, items: data?.items ?? [] });

  const pageChange = useCallback(
    (newPage: number) => {
      // console.log('pageChange', { page: newPage });
      setState((state) => ({ ...state, page: newPage }));
    },
    [setState]
  );
  const pageSizeChange = useCallback(
    (newPageSize: number) => setState((state) => ({ ...state, pageSize: newPageSize, page: 0 })),
    [setState]
  );
  const sortChange = useCallback(
    (sort: SearchState['sort']) => {
      setState((state) => ({ ...state, sort }));
    },
    [setState]
  );
  const searchChange = useCallback(
    (values: Record<string, unknown>) => {
      setState((state) => ({
        ...state,
        form: {
          ...values,
          ...forceForm,
          _t: Date.now(), // append timestamp to force refetch
        },
        page: 0,
      }));
    },
    [setState, forceForm]
  );
  return {
    searchState,
    result,
    queryKey,
    pageChange,
    pageSizeChange,
    sortChange,
    searchChange,
  };
}
