import { createFileRoute } from '@tanstack/react-router';
import { typographyVariants } from '@/components/ui/typography';
import { cn } from '@/lib/utils';
import LogbookActivityView from '@/routeComponents/logbookActivity/LogbookActivityView';

const LogbookActivityPage = () => {
  return (
    <main className="container mx-auto py-8">
      <h1 className={cn(typographyVariants({ variant: 'h2' }))}>Logbook Activity</h1>
      <LogbookActivityView />
    </main>
  );
};

export const Route = createFileRoute('/_authenticated/logbook-activity/')({
  component: LogbookActivityPage,
});
